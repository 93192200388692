import React from 'react'
import Helmet from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import _get from 'lodash/get'
import { links } from '../components/NavBar/constants'

import Layout from '../components/Layout'

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query NotFoundPageQuery {
        globalSettings: settingsYaml {
          siteTitle
        }
      }
    `}
    render={data => (
      <Layout>
        <Helmet>
          <title>Página não encontrada</title>
        </Helmet>
        <section className="bg-dark pt-40 pb-8 md:pb-16">
          <div className="max-w-screen-xl mx-auto text-white text-center px-2 md:px-8">
            <h1 className="text-4xl mb-4">Que pena...</h1>
            <h2 className="mreaves text-lg mb-16">
              A página que você está buscando não foi encontrada.
            </h2>
            <p className="text-center md:text-left text-base ml-0 md:ml-32 mb-4">
              Você pode estar buscando por:
            </p>
            <div className="flex flex-col md:flex-row justify-around">
              {links.map(
                link =>
                  !link.isExternal && (
                    <Link
                      className="text-base p-2"
                      key={link.name}
                      to={link.value}
                    >
                      <p className="px-3 md:px-8 py-2 md:py-4 bg-primary rounded-lg">
                        {link.name}
                      </p>
                    </Link>
                  )
              )}
            </div>
          </div>
        </section>
      </Layout>
    )}
  />
)
